import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Navigate = ({ to, id, slug }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // If id or slug is provided, include them in the state, otherwise navigate without state
    const navigationState = id || slug ? { state: { id, slug } } : {};
    
    navigate(to, { replace: true, ...navigationState });
  }, [navigate, to, id, slug]);

  return null; // This component doesn't render anything
};

export default Navigate;
