import React from "react";
import ActivitiesDrawer from "./ActivitiesDrawer";
import FoodDrawer from "./FoodDrawer";
import DependentForm from "./DependentForm";
import Reveal from "../../../shared/reveal/Reveal";

export const DependentBookingInfo = React.memo(
  ({ user, food, activities, index, type,errors }) => {
    return (
      <>
        <Reveal>
          <div className="kn-guest-booking-info">
            <DependentForm user={user} index={index} type={type} errors={errors} />
            {/* Activity Add Cta */}
            {/* <ActivitiesDrawer
              user={user}
              currentIndex={index}
              currentUserType={type}
              activities={activities}
            /> */}
            {/* Meals Add Cta */}
            {/* <FoodDrawer
              user={user}
              currentIndex={index}
              currentUserType={type}
              food={food}
            /> */}
          </div>
        </Reveal>
      </>
    );
  }
);
