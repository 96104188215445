import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useUser } from "../../contexts/UserContext";

const PaymentTypeSelection = ({
  paymentTypes,
  selectedPaymentType,
  handlePaymentSelection,
  totalPaymentPrice,
  booking,
  language,
  translate,
  isUpdating, // New prop to control rendering for update flow
}) => {
  const { user } = useUser();
  // Helper function to check if all users are family members
  const isSingleUser = booking?.booking_users?.length === 1;

  console.log("booking?.bundle_ticket_id", booking?.bundle_ticket_id);
  // Determine if split should be disabled
  const shouldDisableSplit = isSingleUser || booking?.bundle_ticket_id;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // When totalPaymentPrice is defined, stop loading
    if (totalPaymentPrice) {
      setLoading(false);
    }
  }, [totalPaymentPrice]);

  // Filter paymentTypes to remove "split" if user_type is "athlete"
  const filteredPaymentTypes =
    user?.user_type === "athlete"
      ? paymentTypes.filter((type) => type !== "split")
      : paymentTypes;

  return (
    <>
      <div className="w-full flex flex-col items-start justify-start gap-4">
        <h3 className="text-[#2A2A2A] text-[18px] font-medium leading-6">
          {language === "en"
            ? "How Would You Like to Pay?"
            : "كيف ترغب في الدفع؟"}
        </h3>

        <div className="w-full flex flex-col md:flex-row items-start justify-start gap-4">
          {filteredPaymentTypes.map((type, index) => (
            <div
              key={index}
              className={`kn-payment-type ${
                selectedPaymentType === type ? "kn-payment-type-active" : ""
              }
               ${
                 type === "split" && shouldDisableSplit
                   ? "kn-payment-type-radio--disabled"
                   : ""
               }
              `}
            >
              <label
                className={`kn-payment-type-radio ${
                  type === "split" && shouldDisableSplit
                    ? "kn-payment-type-radio--disabled"
                    : ""
                }`}
              >
                <span>
                  {loading ? (
                    language === "en" ? (
                      <TailSpin width={20} height={20} color="#FCD12A" />
                    ) : (
                      <TailSpin width={20} height={20} color="#FCD12A" />
                    )
                  ) : type === "pay in full" ? (
                    `${
                      language === "en" ? "Pay Full Bill" : "الدفع بالكامل"
                    } (${totalPaymentPrice} ${translate("currency")})`
                  ) : language === "en" ? (
                    "Split Payment"
                  ) : (
                    "الدفع المقسم"
                  )}
                </span>
                <input
                  className="self-center"
                  type="radio"
                  name="paymentType"
                  value={type}
                  disabled={type === "split" && shouldDisableSplit}
                  checked={selectedPaymentType === type}
                  onChange={() => handlePaymentSelection(type)}
                />
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PaymentTypeSelection;
