import React, { useEffect, useState } from "react";
import appleIconActive from "../../assets/payment-icons/apple-icon-active.svg";
import { useLanguage } from "../../contexts/LanguageContext";
import axiosInstance from "../../axios/axiosInstance";
import { useUser } from "../../contexts/UserContext";
import { useBooking } from "../../contexts/BookingContext";
import { useNavigate, useLocation } from "react-router-dom";

const ApplePay = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const { booking } = useBooking();
  const { user } = useUser();
  const [status, setStatus] = useState("Checking Apple Pay support...");
  const [isApplePaySupported, setIsApplePaySupported] = useState(false);
  const [storeData, setStoreData] = useState(null);
  console.log("🚀 ~ ApplePay ~ storeData:", storeData);
  console.log("🚀 ~ ApplePay ~ isApplePaySupported:", isApplePaySupported);

  console.log(window.ApplePaySession);

  useEffect(() => {
    if (window.ApplePaySession) {
      console.log("Apple Pay is supported in this browser.");
      setStatus("Apple Pay is supported.");

      if (window.ApplePaySession.canMakePayments()) {
        console.log("Apple Pay can make payments.");
        setStatus("Apple Pay is ready to use.");
        setIsApplePaySupported(true);
      } else {
        console.log("Apple Pay is not ready to make payments.");
        setStatus(
          language === "en"
            ? "Apple Pay is not ready to make payments."
            : " Apple Pay غير جاهز لإجراء المدفوعات."
        );
      }
    } else {
      console.log("Apple Pay is not supported in this browser.");
      setStatus(
        language === "en"
          ? "Apple Pay is not supported in this browser."
          : " Apple Pay غير مدعوم في هذا المتصفح."
      );
    }
  }, []);

  const checkApplePayAvailability = () => {
    if (!window.ApplePaySession) {
      console.error("Apple Pay JS API is not available");
      return false;
    }

    if (!window.ApplePaySession.canMakePayments()) {
      console.error("This device cannot make Apple Pay payments");
      return false;
    }

    return true;
  };

  // FIRST 1-> CALL ENDPOINT STORE WITH REGISTER > WILL RETURN BOOKING ID + TOTAL AMOUNT
  // THEN  2-> CALL ENDPOINT VALIDATE MERCHANT   >  IF SUCCESSFUL RETURN MERCHANT SESSION
  // THEN  3-> CALL ENDPOINT PROCESS APPLE PAY   >  IF SUCCESSFUL RETURN PAYMENT SUCCESSFUL

  // const handleApplePay = async () => {
  //   if (!checkApplePayAvailability()) {
  //     alert("Apple Pay is not available on this device/browser");
  //     return;
  //   }
  //   try {
  //     const response = await axiosInstance.post(
  //       "/sports/booking/store-with-register",
  //       booking
  //     );
  //     setStoreData(response.data.data.payment_data);
  //     if (response.status === 200) {

  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }

  //   const paymentRequest = {
  //     countryCode: process.env.REACT_APP_PAYTABS_COUNTRY_CODE,
  //     currencyCode: process.env.REACT_APP_PAYTABS_CURRENCY,
  //     supportedNetworks: ["visa", "masterCard", "amex"],
  //     merchantCapabilities: ["supports3DS"],
  //     total: {
  //       label: process.env.REACT_APP_PAYTABS_MERCHANT_NAME,
  //       amount: "10.00",
  //     },
  //   };

  //   const session = new window.ApplePaySession(3, paymentRequest);

  //   session.onvalidatemerchant = (event) => {
  //     console.log("Validating merchant:", event.validationURL);

  //     fetch(`${process.env.REACT_APP_API_BASE_URL}/apple-pay/validate-merchant`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ validationURL: event.validationURL }),
  //     })
  //       .then((response) => response.json())
  //       .then((merchantSession) => {
  //         console.log("Merchant validation successful:", merchantSession);
  //         session.completeMerchantValidation(merchantSession);
  //       })
  //       .catch((error) => {
  //         console.error("Merchant validation failed:", error);
  //         setStatus("Merchant validation failed.");
  //       });
  //   };

  //   session.onpaymentauthorized = (event) => {
  //     console.log("Payment authorized:", event.payment);

  //     fetch(`${process.env.REACT_APP_PROCESS_APPLE_PAY_ROUTE}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-CSRF-TOKEN": process.env.REACT_APP_CSRF_TOKEN,
  //       },
  //       body: JSON.stringify({ paymentData: event.payment.token.paymentData }),
  //     })
  //       .then((response) => response.json())
  //       .then((result) => {
  //         if (result.success) {
  //           console.log("Payment successful:", result);
  //           session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
  //           alert("Payment successful!");
  //         } else {
  //           console.error("Payment failed:", result);
  //           session.completePayment(window.ApplePaySession.STATUS_FAILURE);
  //           alert("Payment failed: " + result.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Payment processing failed:", error);
  //         session.completePayment(window.ApplePaySession.STATUS_FAILURE);
  //         alert("Payment failed due to a processing error.");
  //       });
  //   };

  //   session.begin();
  // };

  // Function to handle booking and storing data
  const handleStoreWithRegister = async () => {
    try {
      const response = await axiosInstance.post(
        "/sports/booking/store-with-register",
        booking
      );
      console.log("🚀 ~ handleStoreWithRegister ~ response:", response);
      if (response.status === 200) {
        setStoreData(response.data.data.payment_data);
        return response.data.data.payment_data;
      }
    } catch (error) {
      console.error("Error during store with register:", error);
      throw error;
    }
  };

  // Function to create Apple Pay session
  const createApplePaySession = () => {
    return {
      countryCode: process.env.REACT_APP_PAYTABS_COUNTRY_CODE,
      currencyCode: process.env.REACT_APP_PAYTABS_CURRENCY,
      supportedNetworks: ["visa", "masterCard", "amex"],
      merchantCapabilities: ["supports3DS"],
      total: {
        label: process.env.REACT_APP_PAYTABS_MERCHANT_NAME,
        amount: "10.00",
      },
    };
  };

  // Function to validate merchant
  // const validateMerchant = async (validationURL) => {
  //   console.log("🚀 ~ validateMerchant ~ validationURL:", validationURL);
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_BASE_URL}/apple-pay/validate-merchant`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ validationURL }),
  //       }
  //     );
  //     return await response.json();
  //   } catch (error) {
  //     console.error("Merchant validation failed:", error);
  //     throw error;
  //   }
  // };

  const validateMerchant = (validationURL) => {
    console.log("🚀 ~ validateMerchant ~ validationURL:", validationURL);

    return fetch(
      `${process.env.REACT_APP_API_BASE_URL}/apple-pay/validate-merchant`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ validationURL }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Merchant validation successful:", data);
        return data;
      })
      .catch((error) => {
        console.error("Merchant validation failed:", error);
        throw error; // Re-throw error to propagate it further if needed
      })
      .finally(() => {
        handleStoreWithRegister();
      });
  };

  // Function to process payment
  const processPayment = async (paymentData) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/apple-pay/process-apple-pay`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ paymentData }),
        }
      );
      return await response.json();
    } catch (error) {
      console.error("Payment processing failed:", error);
      throw error;
    }
  };

  // Main function to handle Apple Pay
  // const handleApplePay = async (booking) => {
  //   if (!checkApplePayAvailability()) {
  //     alert("Apple Pay is not available on this device/browser");
  //     return;
  //   }

  //   // Step 1: Store with register and get payment data
  //   const paymentData = await handleStoreWithRegister(booking);

  //   // Step 2: Create Apple Pay session
  //   const paymentRequest = createApplePaySession();
  //   const session = new window.ApplePaySession(3, paymentRequest);
  //   console.log("🚀 ~ handleApplePay ~ session:", session);

  //   // Step 3: Validate merchant
  //   session.onvalidatemerchant = async (event) => {
  //     alert("Validating merchant...");
  //     try {
  //       const merchantSession = await validateMerchant(event.validationURL);
  //       console.log(
  //         "🚀 ~ session.onvalidatemerchant= ~ merchantSession:",
  //         merchantSession
  //       );
  //       session.completeMerchantValidation(merchantSession);
  //     } catch (error) {
  //       console.error("Merchant validation failed:", error);
  //       session.abort();
  //     }
  //   };

  //   // Step 4: Handle payment authorization
  //   session.onpaymentauthorized = async (event) => {
  //     const result = await processPayment(event.payment.token.paymentData);
  //     if (result.success) {
  //       session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
  //       alert("Payment successful!");
  //     } else {
  //       session.completePayment(window.ApplePaySession.STATUS_FAILURE);
  //       alert("Payment failed: " + result.message);
  //     }
  //   };

  //   // Step 5: Start Apple Pay session
  //   session.begin();
  // };

  return (
    <div>
      {!isApplePaySupported && (
        <div className="w-full h-auto mb-[2rem] rounded-[6px] p-[16px] bg-[#FDE8E8]">
          <p className="text-[#C81E1E] text-[14px] font-normal leading-5">
            {status}
          </p>
        </div>
      )}

      {isApplePaySupported && (
        <button
          onClick={async () => {
            // Check if Apple Pay is available on this device/browser
            if (!checkApplePayAvailability()) {
              alert("Apple Pay is not available on this device/browser");
              return;
            }

            // Step 1: Create the Apple Pay session immediately
            const paymentRequest = createApplePaySession();
            console.log("🚀 ~ paymentRequest:", paymentRequest);

            // Initialize Apple Pay session
            const session = new window.ApplePaySession(3, paymentRequest);
            console.log("🚀 ~ session:", session);

            // Step 2: Define session handlers
            // Merchant validation

            session.onvalidatemerchant = async (event) => {
              console.log("Validating merchant:", event.validationURL);
              try {
                const merchantSession = await validateMerchant(
                  event.validationURL
                );
                console.log("Merchant session received:", merchantSession);
                session.completeMerchantValidation(merchantSession);
              } catch (error) {
                console.error("Merchant validation failed:", error);
                session.abort(); // Abort session on validation failure
              }
            };

            // Payment authorization
            session.onpaymentauthorized = async (event) => {
              console.log("Payment authorized:", event.payment);

              try {
                const result = await processPayment(
                  event.payment.token.paymentData
                );
                if (result.success) {
                  session.completePayment(
                    window.ApplePaySession.STATUS_SUCCESS
                  );
                  alert("Payment successful!");
                } else {
                  session.completePayment(
                    window.ApplePaySession.STATUS_FAILURE
                  );
                  alert("Payment failed: " + result.message);
                }
              } catch (error) {
                console.error("Payment processing failed:", error);
                session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                alert("Payment failed due to a processing error.");
              }
            };

            // User cancels payment
            session.oncancel = () => {
              console.log("Payment was cancelled by the user.");
            };

            // Step 3: Begin the session
            session.begin();
          }}
          className="w-full bg-black flex items-center justify-center gap-2 py-[6px] px-[14px] md:py-[8px] md:px-[20px] rounded-[100px] text-white font-medium leading-6 text-[12px] md:text-[16px]"
        >
          Pay with
          <img
            src={appleIconActive}
            alt="Apple Pay"
            className="w-[48px] h-[48px]"
          />
        </button>
      )}
    </div>
  );
};

export default ApplePay;

{
  /* <button
  onClick={handleApplePay}
  className="w-full bg-black flex items-center justify-center gap-2  py-[6px] px-[14px] md:py-[8px] md:px-[20px] rounded-[100px] text-white
    font-medium leading-6 text-[12px] md:text-[16px] "
>
  Pay with
  <img
    src={appleIconActive}
    alt="Apple Pay"
    className="w-[48px] h-[48px]"
  />
</button> */
}
